<script>

import { HomeIcon, MailIcon } from "vue-feather-icons";
import Navbar from "@/components/navbar0";

import store from "@/store/store";
/**
 * Auth-re-password-three component
 */
export default {
  data() {
    return {
      input2sql: {
        username: '',
        password: '',
      },
      showStatus: false,
      noUserStatus: false,
      errorStatus: false,
    };
  },
  components: {
    Navbar,
    HomeIcon,
    MailIcon,
  },

  methods: {

    updatePassword(){


        this.input2sql.username = this.$route.params.username;


        this.axios({
          method: 'post',
          url: '' + this.$hostname + '/reset-password-update?access_token=' + this.$route.params.token,
          data: this.input2sql,
        }).then((res) => {
          console.log(res.data)
          if (res.data.status === 'success') {
            store.commit('Update_passwordChanged_State', true);
            console.log(store.state.passwordChanged)
            this.$router.push({path:'/login'});
          } else if (res.data.status === 'failed') {
            this.errorStatus= true;
          } else {
            this.errorStatus= true;
          }

        }).catch((e) => {
          this.errorStatus= true;
        });


      }



  },

};
</script>

<template>
  <div>

    <Navbar />

    <div class="back-to-home rounded d-none d-sm-block">
      <router-link to="/" class="btn btn-icon btn-primary">
        <home-icon class="icons"></home-icon>
      </router-link>
    </div>

    <!-- Hero Start -->
    <section class="bg-half-170 ">
      <div class="bg-overlay bg-overlay-white"></div>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-5 col-md-8">
            <div class="card login_page shadow rounded border-0">
              <div class="card-body">
                <h4 class="card-title text-center">Новый пароль</h4>

                <p>Пожалуйста введите новый пароль.
                <form class="login-form mt-4">
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="form-group">
                        <label
                          >Пароль
                          <span class="text-danger">*</span></label
                        >
                        <div class="position-relative">
                          <mail-icon class="fea icon-sm icons"></mail-icon>
                          <input
                            type="password"
                            class="form-control pl-5"
                            placeholder="Новый пароль"
                            name="email"
                            v-model="input2sql.password"
                          />
                        </div>
                      </div>
                    </div>


                    <div class="col-lg-12">
                      <!--
                      <button class="btn btn-primary btn-block">Восстановить пароль</button>
                      -->

                      <vs-button ref="updatePasswordButton" size="xl" type="button" class="btn btn-primary btn-block" @click="updatePassword">
                        Сохранить
                      </vs-button>


                    </div>
                    <div class="mx-auto">
                      <p class="mb-0 mt-3">
                        <small class="text-dark mr-2"
                          >Помните пароль ?</small
                        >
                        <router-link
                          to="/login"
                          class="text-dark font-weight-bold"
                          >Войти</router-link
                        >
                      </p>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
    </section>
    <!--end section-->
    <!-- Hero End -->

  </div>
</template>
